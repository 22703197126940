<template>
  <div class="pt-2 d-flex d-flex flex-md-row flex-column-reverse">
    <div class="col-md-9 nopadding">
      <no-access-info-card
        v-if="showNoAccessInfoCard"
      />
      <div v-else-if="audios.meta && audios.data">
        <div>
          <AudioVideoCard
            v-for="(audio) in audios.data"
            :key="audio.id"
            :item="audio.attributes"
            :can-show-image="showImages"
            @click="$router.push(`/audio/${audio.id}`)"
            @onImgLoad="loadedImages+= 1"
          />
        </div>
        <div
          v-if="!audios || !audios.data.length"
          class="d-flex align-items-center justify-content-center"
        >
          <b-img
            src="@/assets/images/pages/course/empty-list.svg"
            alt="Buypass"
            class="d-inline-block mt-5"
          />
        </div>
        <div class="d-flex align-items-center justify-content-center w-100">
          <b-pagination
            v-if="audios.meta.pagination.total > audios.meta.pagination.per_page"
            v-model="currentPage"
            :per-page="audios.meta.pagination.per_page"
            :total-rows="audios.meta.pagination.total"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
    <div class="col-md-3 pl-md-1 pr-md-0 p-0">
      <b-input-group class="input-group-merge mb-1">
        <b-input-group-prepend
          class="no-border"
          is-text
        >
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>

        <b-form-input
          v-model="search"
          type="search"
          class="no-border"
          :placeholder="$t('Search...')"
        />
      </b-input-group>
      <b-card
        v-if="categories.length"
        body-class="p-1"
      >
        <div class="mb-1">
          <h4 class="font-weight-bolder">
            {{ $t("Category") }}
          </h4>
          <b-form-checkbox
            v-for="category in translate(categories, this.$i18n.locale)"
            :key="category.id"
            v-model="selectedCategories"
            :value="category.id.toString()"
            class="custom-control-primary mt-1"
          >
            <h5>{{ category.name }}</h5>
          </b-form-checkbox>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BPagination, BImg, BCard, BFormCheckbox, BInputGroupPrepend, BInputGroup, BFormInput,
} from 'bootstrap-vue'

import { debounce } from 'vue-debounce'

import NoAccessInfoCard from '@/components/NoAccessInfoCard.vue'
import AudioVideoCard from '@/components/AudioVideoCard.vue'

export default {
  components: {
    BPagination,
    BImg,
    BCard,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,

    NoAccessInfoCard,
    AudioVideoCard,
  },
  data() {
    return {
      loadedImages: 0,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    showImages() {
      return this.loadedImages === this.audios.data.filter(el => el.attributes.image).length
    },
    userPermissions() {
      return this.$store.getters['auth/permissions']
    },
    audios() {
      return this.$store.getters['audio/audios']
    },
    currentPage: {
      get() {
        return this.$store.getters['audio/pageNumber']
      },
      set(pageNumber) {
        this.$store.commit('audio/setPageNumber', pageNumber)
        this.$store.dispatch('audio/fetchAudios')
      },
    },
    query() {
      return this.$store.getters['audio/query']
    },
    search: {
      get() {
        return this.$store.getters['audio/search']
      },
      // eslint-disable-next-line
      set: debounce(function(search) {
        this.$store.commit('audio/setSearch', search)
      }, 1000),
    },
    categories() {
      return this.$store.getters['audio/categories']
    },
    selectedCategories: {
      get() {
        return this.$store.getters['audio/selectedCategories']
      },
      set(selectedCategories) {
        this.$store.commit('audio/setSelectedCategories', selectedCategories)
      },
    },
    representACompanyAdmin() {
      return 'id' in this.user.active_business_profile && this.user.active_business_profile.role === 'admin'
    },
    showNoAccessInfoCard() {
      if (this.representACompanyAdmin) return false
      if (!this.checkPermission('AUDIO_READ', this.userPermissions)) return true
      return false
    },
  },
  watch: {
    // eslint-disable-next-line
    '$route.query.filter': {
      handler() {
        this.loadedImages = 0
        this.$store.dispatch('audio/fetchAudios')
      },
      deep: true,
    },
    query: {
      handler(query) {
        this.updateQuery(query)
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.showNoAccessInfoCard) {
      if (this.$route.query.filter) {
        this.$store.commit('audio/setQuery', JSON.parse(this.$route.query.filter))
      }
      this.$store.dispatch('audio/fetchAudios')
      this.$store.dispatch('audio/fetchCategories')
    }
  },
  beforeDestroy() {
    this.$store.commit('audio/clearFilters')
  },
  methods: {
    updateQuery(query) {
      this.$router.push({
        path: '/audio',
        query: { filter: JSON.stringify(query) },
      }).catch(() => {})
    },
  },
}
</script>
